import {LoadingState} from "constants/redux";
import {UserAccount, UserAccountPermissions, UserData} from "@sense-os/goalie-js";

export interface LoginTwoFAState {
	loadingState: LoadingState;
	/**
	 * Error when user fill in 2FA code
	 */
	codeError: Error;
	/**
	 * Attempt count when user fill in 2FA code
	 */
	codeAttemptCount: number;
	/**
	 * Error when user fill in 2FA Recovery code
	 */
	recoveryCodeError: Error;
	/**
	 * Attempt count when user fill in 2FA Recovery code
	 */
	recoveryCodeAttemptCount: number;
}

export type PasswordResetTwoFAState = LoginTwoFAState;

export interface AuthState {
	user?: AuthUser;
	emailVerificationLoadingState: LoadingState;
	loginLoadingState: LoadingState;
	loginTwoFA: LoginTwoFAState;
	passwordResetTwoFA: PasswordResetTwoFAState;
	isLoginBlocked: boolean;
	isLoggedIn: boolean;
	registrationLoadingState: LoadingState;
	resetPasswordLoadingState: LoadingState;
	changePasswordLoadingState: LoadingState;
	logoutLoadingState: LoadingState;
	error?: Error;
}

export enum AuthUserRole {
	CLIENT = "client",
	THERAPIST = "therapist",
}

/**
 * Authenticated User Data
 */
export interface AuthUser {
	id: number;
	fullName: string;
	firstName: string;
	lastName: string;
	hashId: string;
	token: string;
	imageUrl?: string;
	externalId: string;
	role: AuthUserRole;
	account: UserAccount;
	data: UserData;
	permissions?: UserAccountPermissions[];
	/**
	 * Organization of auth user.
	 * Empty if user is not part of any organisation.
	 */
	organization?: {
		id: number;
		name: string;
		logo?: string;
		isAccepted?: boolean;
		/**
		 * Date of when the user accepted at the organization.
		 * Empty if the user hasn't accepted yet.
		 */
		dateJoined?: Date;
		/**
		 * Organization's group id
		 */
		groupId?: number;
	};
}
